(function($, w, undefined) {
    function DonationShow(args) {
        this.setEvents();
    }
    
    DonationShow.prototype.setEvents = function() {
        window.addEventListener('hashchange', this.onHashChange);
    }
    
    DonationShow.prototype.onHashChange = function(event) {
        var parts = event.newURL.split('#');
        if(parts.length && parts.length === 2) {
            var hash = parts[1];
            if(hash === 'donation') {
                var donation = document.querySelector('.module.module-donation');
                if(typeof donation !== 'undefined') {
                    donation.scrollIntoView();
                    window.location.hash = 'donation_shown';
                }
            }
        }
    }

    w.RFDS = w.RFDS || {};
    w.RFDS.Components = w.RFDS.Components || {};
    w.RFDS.Components.DonationShow = DonationShow;
})(jQuery, window);